const ParallaxInfo = () => {
  return (
    <div className="bg-fixed bg-parallax bg-cover min-h-screen h-100 snap-start lg:snap-center">
      <div className="max-w-[1440px] mx-auto w-full">
        <div className="flex flex-col text-center items-center justify-center min-h-screen h-100 max-w-[1140px] w-full mx-auto px-7 md:px-24 py-28 lg:py-32">
          <h1 className="text-[47px] lg:text-[58px] font-bold text-digitayoWhite leading-[130%] mb-5">
            Iba’t-ibang experience level, <div className="h-0 none inline-flex lg:block"><br /></div>isang DigiTayo to empower all.
          </h1>
          <p className="text-2xl lg:text-[32px] text-digitayoWhite leading-[130%]">
            Nagsisimula pa lang sa digital o sanay na, <div className="h-0 none inline-flex lg:block"><br /></div>andito ang DigiTayo para tulungan ka sa digital journey mo.
          </p>

        </div>
      </div>
    </div>
    
  );
};

export default ParallaxInfo;