import GoGrowCard from './GoGrowCard';
import data from '../data/go-grow.json';

const GoGrowDigitalInfo = () => {
  return (
    <div className="bg-gogrow bg-cover min-h-screen h-100 snap-start lg:snap-center">
      <div className="max-w-[1440px] mx-auto w-full py-28 lg:py-32">
        <div className="max-w-[1140px] w-full mx-auto">
          <h1 className="px-7 md:px-14 bg-gradient-to-r from-digitayoOrange1 to-digitayoPurple1 bg-clip-text text-transparent text-[47px] leading-[52px] font-bold">
            Why Go and Grow Digital?
          </h1>
          <div className='px-6 xl:px-0 mt-[75px]'>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6 gap-y-8 md:gap-8 lg:gap-10 lg:gap-y-12 w-full">
              {data.map(item => (
                <GoGrowCard
                  title={item.title}
                  description={item.description}
                  tagline={item.tagline}
                  image={item.icon}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoGrowDigitalInfo;
