import AOS from 'aos';
import 'aos/dist/aos.css';
import React, { useEffect } from 'react';
import './App.css';
import Landing from './Landing';

AOS.init();

const App: React.FC = () => {

  useEffect(() => {
		setTimeout(() => {
			AOS.init({
				duration: 1000,
			});
		}, 1000);
	}, []);

  return (
    <Landing />
  );
}

export default App;
