import course from '../assets/course.svg';

const CourseInfo = () => {
  return (
    <div className="bg-digitayoOrange1 min-h-screen h-100 snap-start lg:snap-center">
      <div className="max-w-[1440px] mx-auto w-full">
        <div className="flex flex-col lg:flex-row items-center justify-center min-h-screen h-100 max-w-[1140px] w-full mx-auto px-7 md:px-14 py-28 lg:py-32">
          <div className="lg:w-1/2 text-left lg:pr-20" data-aos="fade-right">
            <h1 className="text-digitayoWhite text-[47px] leading-[130%] font-bold mb-5">
              Palaguin ang knowledge, palaguin ang negosyo
            </h1>
            <p className="text-digitayoCreated1 text-lg mb-4">
              Mag-enroll sa libreng courses para matuto or maturuan ang iyong staff ng bagong
              skills, tulad ng online marketing, digital inventory, at iba pa.
            </p>
          </div>
          <div className="lg:w-1/2 mt-14 lg:mt-0 flex justify-center">
            <img
              src={course}
              alt="DigiTayo Mobile App"
              className="w-full lg:w-[681px] max-w-[681px] lg:-mr-[50px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseInfo;
