import React from 'react';
import AboutInfo from './Components/AboutInfo';
import CourseInfo from './Components/CourseInfo';
import ResourceInfo from './Components/ResourceInfo';
import BlogInfo from './Components/BlogInfo';
import TopInfo from './Components/TopInfo';
import Footer from './Components/Footer';
import ParallaxInfo from './Components/ParallaxInfo';
import GoGrowDigitalInfo from './Components/GoGrowDigitalInfo';

function App() {
  return (
    <div className="relative overflow-hidden min-h-screen h-100 bg-white font-archivo snap-y snap-mandatory overflow-y-scroll">
      <TopInfo />
      <AboutInfo />
      <GoGrowDigitalInfo />
      <ParallaxInfo />
      <CourseInfo />
      <BlogInfo />
      <ResourceInfo />
      <Footer />
    </div>
  );
}

export default App;
