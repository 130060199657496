import blog from '../assets/blog.svg';

const BlogInfo = () => {
  return (
    <div className="bg-digitayoPurple1 min-h-screen h-100 snap-start lg:snap-center">
      <div className="max-w-[1440px] mx-auto w-full">
        <div className="flex flex-col lg:flex-row items-center justify-center min-h-screen h-100 max-w-[1140px] w-full mx-auto px-7 md:px-14 py-28 lg:py-32">
          <div className="lg:w-1/2 mt-14 lg:mt-0 flex justify-center order-2 lg:order-1">
            <img
              src={blog}
              alt="DigiTayo Mobile App"
              className="w-full lg:w-[635px] max-w-[635px] lg:-ml-[75px]"
            />
          </div>
          <div className="lg:w-1/2 text-left lg:pl-20 order-1 lg:order-2" data-aos="fade-left">
            <h1 className="text-digitayoWhite text-[47px] leading-[130%] font-bold mb-5">
              Inspiring stories, educational blogs
            </h1>
            <p className="text-digitayoCreated1 text-lg mb-4">
              Basahin ang latest stories tungkol sa digital transformation at pagnenegosyo.
              Ma-inspire sa kwento ng ibang successful negosyante o pumulot ng tips and advice mula
              sa experts.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogInfo;
