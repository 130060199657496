
type CardType = {
  title: string;
  description: string;
  tagline: string;
  image: any,
};

const GoGrowCard = ({ title, description, tagline, image }: CardType) => {
  return (
    <div className="text-center" data-aos="fade-up">
      <div className="flex justify-center mb-[5px]">
        <img className="w-full h-auto max-w-[148px] object-contain" src={image} alt={title} />
      </div>
      <h2 className="text-lg font-bold text-digitayoBlack4 leading-[130%]">{title}</h2>
      <p className="text-base text-digitayoBlack2 mt-2 leading-[130%]">{tagline}</p>
      {/* <p className="text-digitayoGray5 text-xs italic mt-2 leading-[130%]">{tagline}</p> */}
    </div>
  );
};

export default GoGrowCard;
