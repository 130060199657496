import resource from '../assets/resource.svg';

const ResourceInfo = () => {
  return (
    <div className="bg-digitayoBlue1 min-h-screen h-100 snap-start lg:snap-center">
      <div className="max-w-[1440px] mx-auto w-full">
        <div className="flex flex-col lg:flex-row items-center justify-center min-h-screen h-100 max-w-[1140px] w-full mx-auto px-7 md:px-14 py-28 lg:py-32">
          <div className="lg:w-1/2 text-left lg:pr-20" data-aos="fade-right">
            <h1 className="text-digitayoWhite text-[47px] leading-[130%] font-bold mb-5">
              Learn more with free resources
            </h1>
            <p className="text-digitayoCreated1 text-lg mb-4">
              Go beyond the lessons in your enrolled courses. Marami pang pwedeng matutunan when you
              access our varied range of resources. Read, learn, and grow your business further!
            </p>
          </div>
          <div className="lg:w-1/2 mt-14 lg:mt-0 flex justify-center">
            <img
              src={resource}
              alt="DigiTayo Mobile App"
              className="w-full lg:w-[553px] max-w-[553px] lg:-mr-[70px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourceInfo;
