import about from '../assets/about.svg';

const AboutInfo = () => {
  return (
    <div className="bg-digitayoWhite min-h-screen h-100 snap-start lg:snap-center">
      <div className="max-w-[1440px] mx-auto w-full">
        <div className="flex flex-col lg:flex-row items-center justify-center min-h-screen h-100 max-w-[1140px] w-full mx-auto px-7 md:px-14 py-28 lg:py-32">
          <div className="lg:w-1/2 text-left lg:pr-20" data-aos="fade-right">
            <h1 className="bg-gradient-to-r from-digitayoOrange1 to-digitayoPurple1 bg-clip-text text-transparent text-[47px] leading-[52px] font-bold mb-5">
              What is DigiTayo?
            </h1>
            <p className="text-digitayoBlack2 text-lg mb-4">
              Ang DigiTayo ay isang app para sa mga Filipino small and medium enterprises (SME) na gustong simulan ang kanilang digital transformation. Maliit ka man na negosyo na walang alam sa digital o mas established na business na gumagamit na ng digital sa operations niya, ang DigiTayo ay narito para tulungan ka maging digitally empowered para ma-achieve kung ano man ang goals mo.
            </p>
          </div>
          <div className="lg:w-1/2 mt-14 lg:mt-0 flex justify-center">
            <img
              src={about}
              alt="DigiTayo Mobile App"
              className="w-full lg:w-[601px] max-w-[601px] lg:-mr-[75px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutInfo;
